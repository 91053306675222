import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Tiles } from '../blocks';
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Picks from my favorite readings over time`}</h2>
    <SEO title="Readings" mdxType="SEO" />
    <h3>{`Technology`}</h3>
    <Tiles mdxType="Tiles">
      <p><a parentName="p" {...{
          "href": "https://bit.ly/33zdmtc"
        }}>{`No, I have no side code projects to show you`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://www.tweag.io/posts/2019-02-06-mapping-open-source.html"
        }}>{`Mapping a universe of open source software`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://overreacted.io/react-as-a-ui-runtime/"
        }}>{`React as a UI runtime`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://bartoszmilewski.com/2014/10/28/category-theory-for-programmers-the-preface/"
        }}>{`Category Theory for Programmers: The Preface`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://danluu.com/algorithms-interviews/"
        }}>{`Algorithms interviews: theory vs. practice`}</a></p>
    </Tiles>
    <h3>{`Others`}</h3>
    <Tiles mdxType="Tiles">
      <p><a parentName="p" {...{
          "href": "https://samkriss.com/2019/12/19/the-case-for-giving-up/"
        }}>{`The case for giving up`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://thinkworth.wordpress.com/2012/08/05/the-womb-of-murder/"
        }}>{`The Womb of Murder`}</a></p>
    </Tiles>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      